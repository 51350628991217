import Constants from '../../config.local.js'

var PensionTypeMixin = {
	data() {
		return {

		}
	},
	methods: {
		loadPensionType: async function(pension_id) {
			const params = { 'pension_id': pension_id }
			const url = this.constructRoute(Constants.PENSION_TYPE_GET_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("PensionTypeMixin::loadPensionType", url)
			.catch(error => {
				console.error("PensionTypeMixin::loadPensionType => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

		addPensionType: async function(pension_accountingplan, pension_label, details, articles, pension_charge) {
			const url = Constants.PENSION_TYPE_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const infos = {
				"pension_accountingplan": pension_accountingplan,
				"pension_label": pension_label,
				"pension_charge": pension_charge,
				"details": details,
				"articles": articles
			}

			const response = await this.$request.request_post_api("PensionTypeMixin::addPensionType", url, infos, false)
			.catch(e => {
				console.error("PensionTypeMixin::addPensionType", e)
				this.failureToast("toast.info_save_failed")
			})

			return response
		},

		editPensionType: async function(pension_id, pension_accountingplan, pension_label, details, articles, tarif, pension_charge) {
			const params = { 'pension_id': pension_id }
			const url = this.constructRoute(Constants.PENSION_TYPE_GET_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY

			const infos = {
				"pension_accountingplan": pension_accountingplan,
				"pension_label": pension_label,
				"pension_charge": pension_charge,
				"details": details,
				"articles": articles,
				"tarif": tarif
			}

			const response = await this.$request.request_post_api("PensionTypeMixin::editPensionType", url, infos, false)
			.catch(e => {
				console.error("PensionTypeMixin::editPensionType", e)
				this.failureToast("toast.info_save_failed")
			})

			return response
		},
	}
}

export default PensionTypeMixin
