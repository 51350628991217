<template>
	<div>
		<div v-if="!addOrEditReady">
			<LoadingSpinner class="col-12" />
		</div>

		<div v-if="addOrEditReady">
			<form @submit.prevent="checkForm">
				<div class="row">
					<div class="col-12" v-if="form_message !== ''">
						<ErrorAlert :messageI18n="form_message" />
					</div>

					<div class="col-12 text-right">
						<a :href="getTrad('compta.aide_pensions_lien')" class="fs-1-3" target="_blank">{{ $t('compta.aide_pensions_text1') }} <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon></a>
					</div>

					<div class="col-12">
						<div class="form-group">
							<label for="pension_label" class="col-form-label">{{ $t("compta.form.label_pension") }} *</label>
							<input type="text" id="pension_label" class="form-control" v-model="form.pension_label" :placeholder="this.getTrad('compta.form.placeholder_pension')" :class="{ 'is-invalid': errors && errors.indexOf('Label') > -1 }">
						</div>
					</div>

					<div class="col-12">
						<div class="form-group">
							<label for="articles" class="col-form-label">{{ $t("compta.form.ajouter_article") }} *</label>
							<e-select
								v-model="form.articles"
								id="articles"
								track-by="id"
								label="name"
								:placeholder="labelTitle"
								:deselectLabel="deselectLabel"
								:selectedLabel="selectedLabel"
								:selectLabel="enter_to_select"
								:options="articles"
								:searchable="true"

								:loading="isLoading"
								:show-labels="false"
								:multiple="true"
								:closeOnSelect="false"
								:class="{ 'is-invalid': errors && errors.indexOf('Articles') > -1 }"
								@remove="removeArticle"
								@select="selectArticle"
							>
								<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
						</div>
					</div>

					<div class="col-12" v-for="(selectedArticle, key) in details" v-bind:key="selectedArticle.id">
						<div class="form-group">
							<label>{{selectedArticle.articles_name}}</label>
							<e-select
							    v-model="details[key].pensionarticle_facturationtype"
							    id="facturation_type"
							    :placeholder="getTrad('horse.type_facturation')"
							    :selectedLabel="selectedLabel"
							    :options="facturation_type"
							    :allow-empty="false"
							    :show-labels="false"
							    :close-on-select="true"
							    :class="{ 'is-invalid': errors && errors.indexOf('facturation_type') > -1 }"
							>
							<template slot="option" slot-scope="{ option }">
								{{ $t('compta.facturation_type.' + option) }}
							</template>
							<template slot="singleLabel" slot-scope="{ option }">
								{{ $t('compta.facturation_type.' + option) }}
							</template>
							</e-select>
							<div class="vue-custom-control-inline">
								<b-form-radio 
									:id="'facturer_journalier_' + key" 
									v-model="details[key].pensionarticle_monthly" 
									value="0" 
								>
									{{ $t("compta.form.journalier") }}
								</b-form-radio>
								<b-form-radio 
									:id="'facturer_mensuel_' + key" 
									v-model="details[key].pensionarticle_monthly" 
									value="1" 
								>
									{{ $t("compta.form.mensuel") }}
								</b-form-radio>
							</div>

							<div class="ml-4" v-if="details[key].pensionarticle_monthly === '0' || details[key].pensionarticle_monthly === 0">
								<div class="form-group">
									<label class="col-form-label mr-1">{{ $t("compta.form.facturer") }} : </label>
									<span class="vue-custom-control-inline">
										<b-form-checkbox 
											:id="'pensionarticle_invoiceable_in_' + key" 
											v-model="details[key].pensionarticle_invoiceable_in" 
											unchecked-value="false"
										>
											{{ $t("compta.form.jour_entree") }}
										</b-form-checkbox>
										
										<b-form-checkbox 
											:id="'pensionarticle_invoiceable_out_' + key" 
											v-model="details[key].pensionarticle_invoiceable_out" 
											unchecked-value="false"
										>
											{{ $t("compta.form.jour_sortie") }}
										</b-form-checkbox>
									</span>
								</div>
							</div>
							<div class="ml-4" v-else>
								<label class="col-form-label mr-1">{{ $t("compta.form.facturer_prorata") }} : </label>
								<span class="vue-custom-control-inline">
									<b-form-radio v-model="details[key].pensionarticle_prorata" value="1">{{ $t("compta.form.oui") }}</b-form-radio>
									<b-form-radio v-model="details[key].pensionarticle_prorata" value="0">{{ $t("compta.form.non") }}</b-form-radio>
								</span>
							</div>
						</div>
					</div>

					<div class="col-12">
						<div class="form-group">
							<label for="pension_charge" class="col-form-label">{{ $t("compta.form.charge") }}</label>
							<input type="text" id="pension_charge" class="form-control" v-model="form.pension_charge" :placeholder="this.getTrad('compta.form.placeholder_pension')" :class="{ 'is-invalid': errors && errors.indexOf('charge') > -1 }">
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Navigation from "@/mixins/Navigation.js"
	import Accounting from "@/mixins/Accounting.js"
	import PensionType from "@/mixins/PensionType.js"
	import Common from '@/assets/js/common.js'

	export default {
		name: "pensionAjout",
		props: ['accountingplan_id', 'pension_id', 'addOrEditReady', 'pension'],
		mixins: [Navigation, Accounting, PensionType],
		data () {
			return {
				labelTitle: this.getTrad("compta.form.search_article"),
				labelTitleMonthly: this.getTrad("compta.form.choose_monthly"),
				enter_to_select: this.getTrad("global.press_enter_to_select"),
				selectedLabel: this.getTrad("global.selected_label"),
				deselectLabel: this.getTrad("global.press_enter_to_remove"),
				articles: [],
				isLoading: false,
				facturation_type: [
				    'pension',
				    'frais'
				],
				form_message: "",
				form: {
					articles: []
				},
				default_form: {
					pension_label: '',
					pension_charge: 0,
					articles: [],
					details: []
				},
				errors: [],
				details: [],
				loading: false,
				monthlyable: [
					{
						id: 0,
						name: this.getTrad("compta.form.journalier")
					},
					{
						id: 1,
						name: this.getTrad("compta.form.mensuel")
					}
				]
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.loading = true
				this.form_message = ""
				this.$emit('update:addOrEditReady', false)

				this.form = this.deppCloneObj(this.default_form)

				let pension = {}
				if(this.pension) {
					pension = this.pension.filter(pension => pension.pension_id == this.pension_id)[0]
				} else {
					// IL FAUT ALLER CHERCHER JUSTE LA PENSION EN BDD AVEC LES ARTICLES ASSOCIÉ
				}

				let name = ''
				if(pension) {
					this.form.pension_label = pension.pension_label
					this.form.pension_charge = pension.pension_charge
					this.form.pension_monthly = (pension.pension_monthly ? 1 : 0)

					if(pension.article !== undefined) {
						if(pension.article.length > 0) {
							await Common.asyncForEach(
                                pension.article,
                                async (article, key) => {
                                    name = await this.formatArticleName(article)
                                    this.form.articles.push({name: name, id: article.articles_id, i: key })

                                    this.details.push({
                                        articles_id: article.articles_id,
                                        articles_name: name,
                                        i: key,
                                        pensionarticle_prorata: article.pensionarticle_prorata,
                                        pensionarticle_monthly: article.pensionarticle_monthly,
                                        pensionarticle_invoiceable_in: article.pensionarticle_invoiceable_in == true,
                                        pensionarticle_invoiceable_out: article.pensionarticle_invoiceable_out == true,
                                        pensionarticle_facturationtype: article.pensionarticle_facturationtype,
                                    })
                                }
                            )
						}
					}
				}

				if(Object.keys(this.articles).length == 0) {
					this.articles = []
					const articles = await this.loadArticles(this.accountingplan_id)
					if(articles.length > 0) {
						await Common.asyncForEach(
							articles, 
							async (article, key) => {
								name = await this.formatArticleName(article)
								this.articles.push({name: name, id: article.articles_id, i: key })
							}
						)
					}
				}

				this.$emit('update:addOrEditReady', true)
				this.loading = false
            },

            async formatArticleName(article) {
                return `${article.articles_label} (${article.articles_code}) - ${await this.priceFormat(article.articles_ht, article.tiers_currency, true)} (${article.vat.vat_label})`
            },

			checkForm: async function (tarif=null) {
				this.form_message = ""
				this.errors = []
				let articles = []

				if(!this.form.articles) {
					this.errors.push("Articles")
				} else {
					if(this.form.articles.length > 0) {
						this.form.articles.forEach((article) => {
							articles.push(article.id)
						})
					}

					if(articles.length == 0) {
					   this.errors.push("Articles")
					}
				}

				if(!this.form.pension_label) {
					this.errors.push("Label")
				}

				if(this.details.length <= 0)
				{
					this.errors.push("Articles")
				}

				if(this.errors.length > 0) {
					return false
				} else {

					let result = null
					if(this.pension_id) {
						if(tarif === 'with_update') {
								result = await this.editPensionType(
								this.pension_id,
								this.accountingplan_id,
								this.form.pension_label,
								this.details,
								articles,
								tarif,
								this.form.pension_charge
							);
						}
						else if(tarif === 'without_update') {
							result = await this.editPensionType(
								this.pension_id,
								this.accountingplan_id,
								this.form.pension_label,
								this.details,
								articles,
								tarif,
								this.form.pension_charge
							);
						}
						else {
							result = await this.editPensionType(
								this.pension_id,
								this.accountingplan_id,
								this.form.pension_label,
								this.details,
								articles,
								tarif,
								this.form.pension_charge
							);
						}
					} else {
						result = await this.addPensionType(
							this.accountingplan_id,
							this.form.pension_label,
							this.details,
							articles,
							this.form.pension_charge
						);
					}

					if(result) {
						return true
					} else {
						this.form_message = "error.LEP"
						return false
					}
				}
			},

			removeArticle(removedOption, id) {
				let articles_id = removedOption.id
				let index = Common.findWithAttr(this.details, "articles_id", articles_id)
				this.details.splice(index, 1);
				this.form.details.splice(index, 1)
			},
			selectArticle(selectedOption, id) {
				this.details.push({
					articles_id: selectedOption.id,
					articles_name: selectedOption.name,
					i: this.details.length + 1,
					pensionarticle_prorata: 0,
					pensionarticle_monthly: 1,
					pensionarticle_invoiceable_in: true,
					pensionarticle_invoiceable_out: true,
					pensionarticle_facturationtype: 'pension',
				})
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert')
		},
		watch: {
			'form.articles'(newval) {
				if(!this.loading) {
					if(newval) {
						if(newval.length == 1) {
							this.form.default = newval[0]
							return
						}
					}

					this.form.default = []
				}
			}
		}
	}
</script>
